<template>
    <div class="user-list">
       <div class="header">
            <div>
                系统名称：<span>{{ titleForm.roleName }}</span>
                状态：<span>{{ titleForm.status==="1"?'有效':"无效" }}</span>
            </div>
       </div>
       <div class="content">
        <el-tabs  v-model="activeName" type="card" @tab-click="handleClick($event)" style="width: 100%;">
            <el-tab-pane label="按组织架构" name="0">
                 <OrganizationalStructure v-if="activeName==='0'" :tableData="tableData1" :activeName="activeName" />
            </el-tab-pane>
            <el-tab-pane label="按岗位" name="1">
                <ByPosition v-if="activeName==='1'" :tableData="tableData2" :activeName="activeName" />
            </el-tab-pane>
            <el-tab-pane label="按人员" name="2">
                <ByPerson v-if="activeName==='2'" :tableData="tableData3" :activeName="activeName" />
            </el-tab-pane>
        </el-tabs>
       </div>
       <div class="button_btm">
            <el-button type="primary" size="mini" @click="saveHandle" :loading="loading">保存</el-button>
            <el-button  size="mini" @click="goBack">取消</el-button>
         </div>
    </div>
</template>
<script>
import OrganizationalStructure from './compontents/organizationalStructure.vue' 
import ByPosition from './compontents/byPosition.vue'
import ByPerson from './compontents/byPerson.vue'
import { 
    querySysRoleRules,
    getSaveSysRoleRules,
} from "@/api/role.js";
import {
  getItem,
  setItem
} from '@/utils/storage'
export default {
    name: "configuration",
    components: { OrganizationalStructure,ByPosition,ByPerson },
    props:{
        tabMenuList:{
            type:Array,
            default(){
                return [
                    {
                        id:1,
                        name:'菜单列表'
                    },
                    {
                        id:2,
                        name:'销售菜单列表'
                    },
                    {
                        id:3,
                        name:'用户菜单列表'
                    }
                ]
            }
        },
        treeList:{
            type:Array,
            default(){
                return []
            }
        }
    },
    data() {
        return {
            loading:false,
            titleForm:{},
            tableData1:[],
            tableData2:[],
            tableData3:[],
            activeName:localStorage.getItem('currentIndex')||'0',
            localstorageTreeListAll:[
                { checkAll:false,list:[],remark:'' },
                { checkAll:false,list:[],remark:'' },
                { checkAll:false,list:[],remark:'' }
            ], // 存储所有节点的数组
            checkAll: false ,// 用于控制全选按钮的选中状态
            form:{
                remark:""
            },
           defaultExpandedKeysList:[2,3], // 展开父节点ID
           defaultCheckedKeysList:[5], // 选中子节点id
           isAllChecked: false, // 用于控制全选框的选中状态
           currentIndex:0,
           defaultProps: {
                children: 'children',
                label: 'label'
            },
            options:[]
        };
    },
   async created() {
        setItem('currentIndex',0)
        this.activeName='0'
        this.titleForm=this.$route.query;
        await this.getGuerySysRoleRules(this.$route.query.id)
    },
    methods: {
        goBack(){
            this.$router.go(-1)
            this.clearLocal()
        },
      async  getGuerySysRoleRules(id){
            try {
                let res= await querySysRoleRules({roleId:id})
                if(res.code===0){
                    this.tableData1=res.data.dept
                    this.tableData2=res.data.job
                    this.tableData3=res.data.user
                    this.$store.commit('setRoleconfiguration1',this.tableData1)
                    this.$store.commit('setRoleconfiguration2',this.tableData2)
                    this.$store.commit('setRoleconfiguration3',this.tableData3)
                }
            } catch (error) {
                
            }
        },
     async   saveHandle(){
            let params={
                    // menuId:4,
                    menuCode:'edit_role_rule',
                    roleId:this.titleForm.id,
                    dept:[],
                    job:[],
                    user:[]
            }
            let dataList=getItem('roleconfiguration1')
            let dataListNew=getItem('roleconfiguration1')
            let dataListCurrent=[]
            dataList.forEach(item=>{
                let  flag=dataListNew.filter(m=>m.deptPathName.includes(item.deptPathName))
                if(flag.length===1){
                    dataListCurrent.push(item)
                }
            })
            dataListCurrent.forEach(item=>{
                params.dept.push({
                    id:item.isAdd?'':item.id,
                    deptId:item.deptId,
                    name:item.name,
                    deptPath:item.deptPath,
                    deptPathName:item.deptPathName
                })
            })
            getItem('roleconfiguration2')?.forEach(item=>{
                params.job.push({
                    id:item.isAdd?'':item.id,
                    jobId:item.jobId,
                    jobName:item.jobName,
                    deptName:item.deptName,
                    deptId:item.deptId,
                    deptPath:item.deptPath,
                    deptPathName:item.deptPathName
                })
            })
            let userList=  getItem('roleconfiguration3')
            userList?.forEach(item=>{
                params.user.push({
                    id:item.isAdd?'':item.id,
                    userId:item.userId,
                    jobNumber:item.jobNumber,
                    fullName:item.fullName,
                    deptId:item.deptId,
                    deptPath:item.deptPath,
                    deptPathName:item.deptPathName
                })
            })
            try {
                this.loading=true
                let res=await getSaveSysRoleRules(params)
                if(res.code===0){
                    this.$router.push({path:'/role'})
                    this.clearLocal()
                    this.$message({
                        type: 'success',
                        message: '保存成功!'
                    });
                }else{
                    this.$message({
                        type: 'error',
                        message: `${res.message||'操作失败!'}`
                    });  
                }
            } catch (error) {
                this.$message({
                    type: 'error',
                    message: `${error||'操作失败!'}`
                });  
            } finally{
                this.loading=false
            }
        },
        clearLocal(){
            localStorage.removeItem("roleconfiguration1");
            localStorage.removeItem("roleconfiguration2");
            localStorage.removeItem("roleconfiguration3");
            localStorage.removeItem("selectTreeList1");
            localStorage.removeItem("selectTreeList2");
            localStorage.removeItem("defaultCheckedKeys");
            localStorage.setItem('currentIndex','0')
        },
        deleteHnadle(row){
            this.$confirm('此操作将永久删除该条数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                this.$message({
                    type: 'success',
                    message: '删除成功!'
                });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });          
                });
        },
        handleClick(event) {
            setItem('currentIndex',event.index)
            this.activeName=event.index.toString()
      }
    }
};
</script>
<style lang="scss" scoped>
.user-list{
    .header{
        display:flex;
        margin: 10px;
        justify-content:space-between;
        font-size: 14px;
        span{
            margin: 0 20px;
            color: rgb(139, 139, 139);
        }
    }
    .content{ 
        display: flex;
        justify-content: space-between; 
    }
    .button_btm{
        box-sizing: border-box;
        width: 100%;
        padding: 20px;
        display: flex;
        justify-content: flex-end;
    }
}
</style>