import axios from 'axios'
let flag = false
// import { getToken, getTenant } from '@/utils/auth'
import { Message } from 'element-ui'
import router from '@/router'
// import { Message } from 'element-ui'
const mimeMap = {
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf8',
  zip: 'application/zip'
}
import store from '../store'
const NODE_ENV = process.env.NODE_ENV

const env = {
  "test": "https://base-oss-test.ehsy.com",
  "development": "https://base-oss-test.ehsy.com",
  // "development": "http://192.168.55.146:8080",
  "staging": "https://sso-staging.ehsy.com",
  // "production": "https://sso.ehsy.com",
  "production": "https://base-oss.ehsy.com",
}

const baseUrl = env[NODE_ENV]
let token = store.state.token
export function downLoadZip(str) {
  const url = baseUrl + '/' + str
  axios({
    method: 'get',
    url,
    responseType: 'blob',
    headers: { 'Authorization': 'Bearer ' + token }
  }).then(res => {
    resolveBlob(res, mimeMap.zip)
  })
}

export function downLoadXlsx(str, params = {}, link = false) {
  if (!flag) {
    flag = true
    const url = baseUrl + '/' + str
    if (link) {
      return handleDownLoadXlsx(url, params, {}, 'get', 'json')
    } else {
      return handleDownLoadXlsx(url, params, {}, 'get', 'blob')
    }
  }
}

export function downLoadPostXlsx(str, data = {}, link = false) {
  if (!flag) {
    flag = true
    const url = baseUrl + '/' + str + '/' + data.id
    if (link) {
      return handleDownLoadXlsx(url, {}, data, 'post', 'json')
    } else {
      return handleDownLoadXlsx(url, {}, data, 'post', 'blob')
    }
  }
}

function handleDownLoadXlsx(url, params, data, method, responseType) {
  return new Promise(resolve => {
    axios({
      method: method,
      url,
      responseType: responseType,
      headers: { 'Authorization': 'Bearer ' + token },
      params: params,
      data: data
    }).then(async res => {
      flag = false
      switch (responseType) {
        case 'json':
          if (res.data.code === 200||res.data.code === 0) {
            window.open(res.data.data.url, '_blank')
          } else {
            Message({
              message: res.data.msg,
              type: 'error',
              duration: 5 * 1000
            })
          }
          break
        case 'blob':
          if (res?.headers['content-type'] === 'application/json; charset=utf-8') {
            const text = await res.data.text()
            const jsonText = await JSON.parse(text)
            resolve(jsonText)
          } else {
            resolveBlob(res, mimeMap.xlsx)
            resolve()
          }
          break
      }
    })
  })
}

export function importXlsx(str, data = {}) {
  const url = baseUrl + '/' + str
  return new Promise(resolve => {
    axios({
      method: 'post',
      url,
      responseType: 'blob',
      headers: { 'Authorization': 'Bearer ' + token },
      data: data
    }).then(async(res) => {
      if (res?.headers['content-type'] === 'application/json;charset=UTF-8') {
        const text = await res.data.text()
        const jsonText = await JSON.parse(text)
        resolve(jsonText)
      } else {
        
        const text = await res.data.text()
        const jsonText = await JSON.parse(text)
        if(jsonText.code===401||jsonText.code===403){
          setTimeout(()=>{
            router.push('/login')
            window.localStorage.clear();
          },500)   
        }else{
          resolveBlob(res, mimeMap.xlsx)
        }

        resolve(jsonText)
      }
    }).catch(async error => {
      const text = await error.response.data.text()
      const jsonText = await JSON.parse(text)
      resolve(jsonText)
    })
  })
}

export function downLoadFile(str) {
  var url = baseUrl + '/' + str
  const aLink = document.createElement('a')
  aLink.href = url
  document.body.appendChild(aLink)
  aLink.click()
  document.body.appendChild(aLink)
}
/**
 * 解析blob响应内容并下载
 * @param {*} res blob响应内容
 * @param {String} mimeType MIME类型
 */
export function resolveBlob(res, mimeType) {
  const aLink = document.createElement('a')
  var blob = new Blob([res.data], { type: mimeType })
  // //从response的headers中获取filename, 后端response.setHeader("Content-disposition", "attachment; filename=xxxx.docx") 设置的文件名;
  const fileName = decodeURI(res.headers['content-disposition']).split('“”')[1] || `${(new Date()).getTime()}`
  // const fileName = '配置规则人员' || `${(new Date()).getTime()}-error`
  aLink.href = URL.createObjectURL(blob)
  aLink.setAttribute('download', fileName) // 设置下载文件名称
  document.body.appendChild(aLink)
  aLink.click()
  document.body.appendChild(aLink)
}
