<template>
    <div class="content_organization">
                    <el-form :inline="true" size="mini" :model="form" ref="filterFormRef" label-width="100px">
                        <el-form-item label="架构:" prop="type">
                            <el-cascader placeholder="请选择架构" :props="{ checkStrictly: true }" v-model="form.type"  :options="options" filterable clearable></el-cascader>
                        </el-form-item>
                        <el-form-item label="岗位名称:" prop="keyword">
                            <el-input v-model="form.keyword"  placeholder="请输入岗位名称" clearable></el-input>
                        </el-form-item>
                        <el-form-item style="margin-left: 20px;">
                            <el-button type="primary" icon="el-icon-search" @click="searchList">查询</el-button>
                            <el-button icon="el-icon-refresh-left" @click="resetFilterForm">重置</el-button>
                        </el-form-item>
                    </el-form>
                        <el-button type="primary" size="mini" icon="el-icon-plus" @click="meuVisibleHnadle" style="float: right;margin-bottom: 10px;">新增配置</el-button>
                        <el-button size="mini" @click="deleteAllHandle" style="float: right;margin-bottom: 10px;margin-right: 10px;">批量删除</el-button>
                       <div class="item_table">
                            <el-table :data="newTableData" border height="300" style="width: 100%;" @selection-change="handleSelectionChange">
                            <el-table-column type="selection" width="55"></el-table-column>
                            <el-table-column   prop="jobName"  label="岗位" width="180"></el-table-column>
                            <el-table-column   prop="deptPathName"  label="所属架构"></el-table-column>
                            <el-table-column   prop="createAt"  label="创建时间" width="180"></el-table-column>
                            <!-- <el-table-column   prop="updateAt"  label="更新时间" width="180"></el-table-column> -->
                            <el-table-column fixed="right" label="操作" width="100">
                                <template slot-scope="scope">
                                    <el-button @click="deleteHnadle(scope.row)" type="text" size="small">删除</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                       </div>
                       <DialogIndex  :dialogVisible="dialogVisible" @changeVisible="changeVisible" :treeData="optionsNew"/>
                </div>
</template>
<script>
import {
  getItem,
  setItem
} from '@/utils/storage'
import DialogIndex from './dialogIndex.vue'
import {  querySysJobTree } from "@/api/role.js";
import { 
    jobTree,
    removeEmptyChildrenJob
 } from '@/utils/commen'
export default {
    name: "byPosition",
    components: { DialogIndex },
    props:{
        tableData:{
            type:Array,
            default(){
                return []
            }
        },
        activeName:{
            type:String,
            default:'0'
        },
    },
    data() {
        return {
            newTableData:getItem('roleconfiguration2')||[],
            treeData:[],
            dialogVisible:false,
            form:{
                keyword:'',
                type:[]
            },
            options:getItem('selectTreeList1')||[],
            optionsNew:getItem('selectTreeList2')||[],
            delSelectList:[]
        };
    },
    watch:{
        tableData: {
            handler: function (newVal, oldVal) {
                this.newTableData=newVal
                this.$store.commit('setRoleconfiguration1',this.newTableData)
                if (newVal.length !== oldVal.length) {
                    console.log('数组长度改变了：', newVal);
                }
            },
        deep: true
        },
    },
 async   created() {
        let arr2=getItem('selectTreeList2')||[]
        arr2?.length===0&&  await   this.getQuerySysJobTree()
    },
    methods: {
        deleteAllHandle(){
            if(this.delSelectList?.length===0){
                this.$message({
                        type: 'warning',
                        message: '请选择需要删除的数据!'
                    }); 
            }else{
                this.$confirm('此操作将永久删除这些数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    this.newTableData=this.newTableData.filter(item=>!this.delSelectList.includes(item.id))
                    this.$store.commit('setRoleconfiguration2',this.newTableData)
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });          
                });
            }
        },
        handleSelectionChange(val){
            this.delSelectList=[]
            val&&val.forEach(item=>{
                this.delSelectList.push(item.id)
            })
            console.log('val',val);
        },
        async getQuerySysJobTree(){ // 岗位
        try {
                let res= await querySysJobTree()
                this.treeData=[]
                this.treeData.push(res.data)
                this.treeData=removeEmptyChildrenJob(this.treeData)
                let arr=jobTree(this.treeData)
                this.optionsNew=arr
                setItem('selectTreeList2',arr)
            } catch (error) { } 
       } ,
        changeVisible(visible,row){
            this.dialogVisible=visible;
            this.$nextTick(()=>{
                let arr=[]
                let num=0
                row&&row.forEach(m=>{
                    num++
                    let isShow=this.newTableData.some(item=>item.jobId===m.id)
                    if(!isShow){
                       m.isJob&&this.newTableData.unshift({
                                ...m,
                            deptPathName:m.deptPathName,
                            isAdd:true,
                            jobName:m.name,
                            jobId:m.id
                        })
                    }else{
                        if(num<2){
                            this.$message({
                                type: 'warning',
                                message: '有部分重复的数据不能添加!'
                            }); 
                        }
                    }
                })
                this.$store.commit('setRoleconfiguration2',this.newTableData)
            })
        },
        clearForm(){
            Object.keys(this.form).forEach(item=>{
                this.form[item]=''
            })
        },
            // 搜索重置
        resetFilterForm() {
            this.clearForm()
            this.newTableData= getItem('roleconfiguration2')||[]
        },
        searchList(){
            let arr=[]
            let arr2=this.newTableData= getItem('roleconfiguration2')
            if(this.form.type?.length>0&&this.form.keyword===''){
                arr=arr2.filter(item=>item.deptPath.includes(String(this.form.type[this.form.type.length-1])))
            }
            if(this.form.keyword&&this.form.type?.length===0){
                arr2.forEach(item=>{
                    if(item.jobName.includes(this.form.keyword)){
                        arr.push(item)
                    }
                })
            }
           if(this.form.type?.length>0&&this.form.keyword){
                arr=arr2.filter(item=>item.deptPath.includes(String(this.form.type[this.form.type.length-1]))&&item.jobName.includes(this.form.keyword))
           }
           if(this.form.type?.length===0&&this.form.keyword===''){
                arr=arr2
           }
            this.newTableData=arr
        },
        meuVisibleHnadle(){
            this.resetFilterForm()
            this.dialogVisible=true
        },
        deleteHnadle(row){
            this.$confirm('此操作将永久删除该条数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    let arr= getItem('roleconfiguration2')
                    arr=arr.filter(item=>item.id!==row.id)
                    this.newTableData=this.newTableData.filter(item=>item.id!==row.id)
                    this.$store.commit('setRoleconfiguration2',arr)
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });          
                });
        },
    },
};
</script>
<style lang="scss" scoped>
 .content_organization{
            padding: 20px 10px;
            width: 98%;
            border: 1px solid rgb(218, 218, 218);
            height: calc(69vh - 100px);
            .item_table{
                width: 100%;
                overflow: auto;
            }
        } 
</style>