<template>
    <div class="content_organization">
                    <el-form :inline="true" size="mini" :model="form" ref="filterFormRef" label-width="100px">
                        <el-form-item label="工号:" prop="jobNumber">
                            <el-input v-model="form.jobNumber" placeholder="请输入工号" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="姓名:" prop="fullName">
                            <el-input v-model="form.fullName" placeholder="请输入姓名" clearable></el-input>
                        </el-form-item>
                        <el-form-item style="margin-left: 20px;">
                            <el-button type="primary" icon="el-icon-search" @click="searchList">查询</el-button>
                            <el-button icon="el-icon-refresh-left" @click="resetFilterForm">重置</el-button>
                        </el-form-item>
                    </el-form>
                        <el-button type="primary" size="mini" icon="el-icon-plus" @click="meuVisibleHnadle" style="float: right;margin-bottom: 10px;">新增配置</el-button>
                        <el-button size="mini" @click="deleteAllHandle" style="float: right;margin-bottom: 10px;margin-right: 10px;">批量删除</el-button>
                       <div class="item_table">
                            <el-table :data="newTableData" border height="300" style="width: 100%;" @selection-change="handleSelectionChange">
                                <el-table-column type="selection" width="55"></el-table-column>
                            <el-table-column   prop="jobNumber"  label="工号"></el-table-column>
                            <el-table-column   prop="fullName"  label="姓名"></el-table-column>
                            <el-table-column   prop="deptPathName"  label="所属架构"></el-table-column>
                            <el-table-column   prop="createAt"  label="创建时间"></el-table-column>
                            <!-- <el-table-column   prop="updateAt"  label="更新时间"></el-table-column> -->
                            <el-table-column fixed="right" label="操作" width="100">
                                <template slot-scope="scope">
                                    <el-button @click="deleteHnadle(scope.row)" type="text" size="small">删除</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                       </div>
                       <DialogIndex  :dialogVisible="dialogVisible" @changeVisible="changeVisible"/>
                </div>
</template>
<script>
import {
  getItem,
  setItem
} from '@/utils/storage'
import DialogIndex from './dialogIndex.vue'
export default {
    name: "byPerson",
    components: { DialogIndex },
    props:{
        tableData:{
            type:Array,
            default(){
                return []
            }
        },
        activeName:{
            type:String,
            default:'0'
        },
    },
    data() {
        return {
            newTableData:getItem('roleconfiguration3')||[],
            dialogVisible:false,
            form:{
                jobNumber:'',
                fullName:''
            },
            delSelectList:[]
        };
    },
    created() {
        setItem('treeDataAll',this.localstorageTreeListAll)
    },
    watch:{
        tableData: {
            handler: function (newVal, oldVal) {
                this.newTableData=newVal
                this.$store.commit('setRoleconfiguration3',this.newTableData)
                if (newVal.length !== oldVal.length) {
                    console.log('数组长度改变了：', newVal);
                }
            },
        deep: true
        }
    },
    methods: {
        deleteAllHandle(){
            if(this.delSelectList?.length===0){
                this.$message({
                        type: 'warning',
                        message: '请选择需要删除的数据!'
                    }); 
            }else{
                this.$confirm('此操作将永久删除这些数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    this.newTableData=this.newTableData.filter(item=>!this.delSelectList.includes(item.userId))
                    this.$store.commit('setRoleconfiguration3',this.newTableData)
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });          
                });
            }
        },
        handleSelectionChange(val){
            this.delSelectList=[]
            val&&val.forEach(item=>{
                this.delSelectList.push(item.userId)
            })
            console.log('val',val);
        },
        changeVisible(visible,row){
            this.dialogVisible=visible;
            this.$nextTick(()=>{
                row&&row.forEach(item=>{
                    this.newTableData.unshift({
                        ...item,
                        deptPathName:item.deptPathName,
                        isAdd:true
                    })
                })
                let arr=this.newTableData.filter((item, index) => { 
                // 使用 index 来确保对象的唯一性，因为对象在数组中的顺序可能会变化
                    return this.newTableData.findIndex(otherItem  => otherItem .jobNumber === item.jobNumber) === index;
                });
                if(this.newTableData.length!==arr.length){
                    this.$message({
                        type: 'warning',
                        message: '重复数据不能添加!'
                    }); 
                }
                this.newTableData=arr
                this.$store.commit('setRoleconfiguration3',arr)
            })
        },
        clearForm(){
            Object.keys(this.form).forEach(item=>{
                this.form[item]=''
            })
        },
            // 搜索重置
        resetFilterForm() {
            this.newTableData=this.newTableData= getItem('roleconfiguration3')
            this.clearForm()
        },
        searchList(){
            let arr=[]
            let arr2=this.newTableData= getItem('roleconfiguration3')
            let { jobNumber,fullName } =this.form
            if(jobNumber&&fullName){
                arr=arr2.filter(item=>item.jobNumber.includes(jobNumber)&&item.fullName.includes(fullName))
            }
            if(jobNumber&&!fullName){
                arr=arr2.filter(item=>item.jobNumber.includes(jobNumber))
            }
            if(!jobNumber&&fullName){
                arr=arr2.filter(item=>item.fullName.includes(fullName))
            }
            if(!jobNumber&&!fullName){
                arr=arr2
            }
            this.newTableData=arr
        },
        meuVisibleHnadle(){
            this.resetFilterForm()
            this.dialogVisible=true
        },
        deleteHnadle(row){
            console.log('row',row);
            this.$confirm('此操作将永久删除该条数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    let arr= getItem('roleconfiguration3')
                    arr=arr.filter(item=>item.userId!==row.userId)
                    this.newTableData=this.newTableData.filter(item=>item.userId!==row.userId)
                    this.$store.commit('setRoleconfiguration3',arr)
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });          
                });
        },
    },
};
</script>
<style lang="scss" scoped>
 .content_organization{
            padding: 20px 10px;
            width: 98%;
            border: 1px solid rgb(218, 218, 218);
            height: calc(69vh - 100px);
            .item_table{
                width: 100%;
                overflow: auto;
            }
        } 
</style>