import axios from '../utils/axios'

let baseURL = '/sysRole'

// 角色列表
export function getQuerySysRolePageList(params) {
    return axios.get(baseURL+'/querySysRolePage', {params})
}

// 新增角色
export function addSysRole(params) {
    return axios.post(baseURL+"/addSysRole",params)
}

// 编辑角色
export function editSysRole(params) {
    return axios.post(baseURL+"/editSysRole?menuId=2", params)
}

// 操作记录
export function getQuerySysRoleOperateLog(params) {
    return axios.get(baseURL+"/querySysRoleOperateLog", {params})
}

// 角色复制
export function copySysRole(params) {
    return axios.post(baseURL+"/copySysRole",params)
}

// 配置规则三个tab页面数据
export function querySysRoleRules(params) {
    return axios.get(baseURL+"/querySysRoleRules",{params})
}

//  配置规则-组织架构树
export function querySysOrgTree(params) {
    return axios.get(baseURL+"/querySysOrgTree",{params})
}

//  配置规则-岗位树
export function querySysJobTree(params) {
    return axios.get(baseURL+"/querySysJobTree",{params})
}
//  下载模版
export function getUserTemplate(params) {
    return axios.get(baseURL+"/download/userTemplate",{params})
}
//  配置规则保存
export function getSaveSysRoleRules(params) {
    return axios.post(baseURL+"/saveSysRoleRules",params)
}
//  分配菜单列表
export function getQuerySysMenuList(params) {
    return axios.get(baseURL+"/querySysMenuList",{params})
}
//  分配菜单树
export function getQuerySysMenuTree(params) {
    return axios.get(baseURL+"/querySysMenuTree",{params})
}
//  保存菜单权限
export function getSaveSysRoleMenu(params) {
    return axios.post(baseURL+"/saveSysRoleMenu",params)
}