<template>
    <div class="content_organization">
                    <el-form :inline="true" size="mini"  ref="filterFormRef" label-width="100px">
                        <el-form-item label="架构:" prop="keyWords">
                            <el-cascader placeholder="请选择架构"  :props="{ checkStrictly: true }" v-model="keyWords" :options="options" filterable></el-cascader>
                        </el-form-item>
                        <el-form-item style="margin-left: 20px;">
                            <el-button type="primary" icon="el-icon-search" @click="searchList">查询</el-button>
                            <el-button icon="el-icon-refresh-left" @click="resetFilterForm">重置</el-button>
                        </el-form-item>
                    </el-form>
                        <el-button type="primary" size="mini" icon="el-icon-plus" @click="meuVisibleHnadle" style="float: right;margin-bottom: 10px;">新增配置</el-button>
                        <el-button size="mini" @click="deleteAllHandle" style="float: right;margin-bottom: 10px;margin-right: 10px;">批量删除</el-button>
                       <div class="item_table">
                            <el-table :data="newTableData" border height="300" style="width: 100%;" @selection-change="handleSelectionChange">
                            <el-table-column type="selection" width="55"></el-table-column>
                            <el-table-column  fixed prop="deptPathName"  label="所属组织架构"></el-table-column>
                            <el-table-column   prop="createAt"  label="创建时间" width="240"></el-table-column>
                            <el-table-column fixed="right" label="操作" width="100">
                                <template slot-scope="scope">
                                    <el-button @click="deleteHnadle(scope.row)" type="text" size="small">删除</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                       </div>
                       <DialogIndex  :dialogVisible="dialogVisible" :treeData="options" @changeVisible="changeVisible"/>
                </div>
                
</template>
<script>
import DialogIndex from './dialogIndex.vue'
import {  querySysOrgTree } from "@/api/role.js";
import {
  getItem,
  setItem
} from '@/utils/storage'
import {  removeEmptyChildrenDeptId } from '@/utils/commen'
export default {
    name: "organizationalStructure",
    components: { DialogIndex },
    props:{
        tableData:{
            type:Array,
            default(){
                return []
            }
        },
        activeName:{
            type:String,
            default:'0'
        },
    },
    data() {
        return {
            newTableData:getItem('roleconfiguration1')||[],
            dialogVisible:false,
            keyWords:[],
            options:getItem('selectTreeList1')||[],
            delSelectList:[]
        };
    },
    watch:{
        tableData: {
            handler: function (newVal, oldVal) {
                this.newTableData=newVal
                this.$store.commit('setRoleconfiguration1',this.newTableData)
                if (newVal.length !== oldVal.length) {
                    console.log('数组长度改变了：', newVal);
                }
            },
        deep: true
  }
    },
  async  created() {
    let arr1=getItem('selectTreeList1')||[]
     arr1.length===0&&  await  this.getQuerySysOrgTree()
    },
    methods: {
        deleteAllHandle(){
            if(this.delSelectList?.length===0){
                this.$message({
                        type: 'warning',
                        message: '请选择需要删除的数据!'
                    }); 
            }else{
                this.$confirm('此操作将永久删除这些数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    this.newTableData=this.newTableData.filter(item=>!this.delSelectList.includes(item.id))
                    this.$store.commit('setRoleconfiguration1',this.newTableData)
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });          
                });
            }
        },
        handleSelectionChange(val){
            this.delSelectList=[]
            val&&val.forEach(item=>{
                this.delSelectList.push(item.id)
            })
            console.log('val',val);
        },
        async getQuerySysOrgTree(){ // 组织架构
        try {
                let res= await querySysOrgTree()
                let arr=[]
                arr.push(res.data)
                 arr=removeEmptyChildrenDeptId(arr)
                this.options=arr
                setItem('selectTreeList1',arr)
            } catch (error) { } 
       },
       flattenTree(tree, result = []) {
            // 遍历树形数据
            tree.forEach(node => {
                // 将当前节点添加到结果数组
                result.push(node);
                // 如果当前节点有子节点，递归地调用函数处理子节点
                if (node.children && node.children.length > 0) {
                    this.flattenTree(node.children, result);
                }
            });
            // 返回转换后的扁平数据数组
            return result;
        },
        changeVisible(visible,row){
            this.$nextTick(()=>{
                let arr=[]
                let num=0
                row&&row.forEach(m=>{
                    if(m.administrativeLevel===2){
                        this.newTableData=[]
                        this.newTableData.unshift({
                                ...m,
                            deptPathName:m.name,
                            isAdd:true
                        })
                        return false
                    }
                    num++
                    let isShow=this.newTableData.some(item=>item.deptId===m.deptId)
                    if(!isShow){
                        let rowTree=[]
                        if(m.children&&m.children.length>0){
                            rowTree=this.flattenTree(m.children)
                        }else{
                            rowTree.push(m)
                        }
                        let isParent=this.newTableData.some(item=>rowTree.some(z=>z.deptPath.includes(item.deptPath)))
                        if(!isParent){
                            this.newTableData.unshift({
                                    ...m,
                                deptPathName:m.deptPathName,
                                isAdd:true
                            })
                        }
                    }else{
                        if(num<2){
                            this.$message({
                                type: 'warning',
                                message: '有部分重复的数据不能添加!'
                            }); 
                        }
                    }
                })
                this.$store.commit('setRoleconfiguration1',this.newTableData)
            })
            this.dialogVisible=visible;
            
        },
          // 搜索重置
        resetFilterForm() {
            this.keyWords=''
            this.newTableData= getItem('roleconfiguration1')||[]
        },
        searchList(){
            let arr=[]
            if(this.keyWords.length>0&&this.newTableData.length>0){
                let str=this.keyWords[this.keyWords.length-1]||null
                arr=this.newTableData.filter(item=>item.deptPath.includes(String(str) ))
            }else{
                arr= getItem('roleconfiguration1')||[]
            }
            this.newTableData=arr
        },
        meuVisibleHnadle(){
            this.resetFilterForm()
            this.dialogVisible=true  
        },
        deleteHnadle(row){
            this.$confirm('此操作将永久删除该条数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    let arr= getItem('roleconfiguration1')
                    arr=arr.filter(item=>item.id!==row.id)
                    this.newTableData=this.newTableData.filter(item=>item.id!==row.id)
                    this.$store.commit('setRoleconfiguration1',arr)
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });          
                });
        },
    },
};
</script>
<style lang="scss" scoped>
 .content_organization{
            padding: 20px 10px;
            width: 98%;
            border: 1px solid rgb(218, 218, 218);
            height: calc(69vh - 100px);
            .item_table{
                width: 100%;
                overflow: auto;
            }
        } 
</style>