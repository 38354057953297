<template>
    <div class="dialog_main">
        <el-dialog
            title="配置规则"
            :visible.sync="dialogVisible"
            width="50%"
            :before-close="handleClose">
            <div class="dialog_content">
                <el-radio-group v-model="value" @input="inputHnadle" disabled>
                    <el-radio label="0">按组织架构</el-radio>
                    <el-radio label="1">按岗位</el-radio>
                    <el-radio label="2">按人员</el-radio>
                </el-radio-group>
                <div class="dialog_content_box" v-if="Number(value)<2">
                     <div class="dialog_content_box_left">
                        <el-tooltip class="item" effect="dark" :content="titleList[value].content" placement="bottom">
                            <i class="el-icon-info dialog_content_box_left_icon"></i>
                        </el-tooltip>
                        <span style="color:red"> *</span> <span>{{titleList[value].title}}</span>
                     </div>
                     <div class="dialog_content_box_right">
                        <template v-if="value==='0'">
                            <!-- <el-checkbox v-model="checkAll" @change="handleCheckAll" style="margin-left: 24px;margin-top: 10px;">全选</el-checkbox> -->
                            <el-tree
                                :data="treeData"
                                show-checkbox
                                node-key="id"
                                ref="tree"
                                highlight-current
                                :props="defaultProps"
                                @check="handleCheck"></el-tree>
                        </template>
                        <template v-else-if="value==='1'">
                            <div class="dialog_content_box_right_item2">
                                <span>岗位: </span> <el-input placeholder="输入关键字进行过滤" v-model="filterText" class="dialog_content_box_right_itemPinpput2"></el-input>
                            </div>
                            <el-tree
                                :data="treeData"
                                show-checkbox
                                node-key="id"
                                ref="selectTree"
                                :props="defaultProps"
                                 :filter-node-method="filterNode"
                                @check="handleCheck2"></el-tree> 
                        </template>
                     </div>
                </div>
                <div class="dialog_content_box" v-else>
                    <el-form ref="form" :model="form">
                        <el-form-item>
                        <el-upload
                            :on-remove="removeFile"
                            :http-request="
                                (param) => {
                                    uploadFile(param);
                                }
                            "
                            :file-list="fileList"
                            action="#"
                            accept=".xls,.xlsx"
                            multiple
                            :limit="1"
                            :before-upload="beforeUpload"
                        >
                            <el-button size="small" type="primary" :disabled="fileList.length >= 1">点击上传</el-button>
                            <div slot="tip" style="color:red">上传格式为Excel的文件，限制大小1M</div>
                           
                        </el-upload>
                        </el-form-item>
                    </el-form>
                    <div class="temClick" @click="getUserTemplateList">下载模版文件</div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="cancelHandle">取 消</el-button>
                <el-button type="primary" @click="confirmHnadle">提 交</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { importXlsx,downLoadXlsx } from '@/utils/download'
import { flattenTree,
    flattenTreeByIds
 } from '@/utils/commen'
export default {
    name: "dialogIndex",
    components: {  },
    props:{
        importParams: {
            type: Object,
            default() {
                return {}
            }
        },
        dialogVisible:{
            type:Boolean,
            default:false
        },
        treeData: {
            type: Array,
            default() {
                return []
            }
        },
    },
    data() {
        return {
            // treeData:[],
            importFileUrl: `${process.env.NODE_ENV}/sysRole/download/userTemplate`,
            file: {},
            form: {},
            flag: false,
            fileList:[],
            selectList1:[],
            selectList2:[],
            filterText: '',
            checkAll: false ,
            checkAll2: false ,
            titleList:[
                {
                    title:'北森组织架构:',
                    content:'在该组织下即为该角色'
                },
                {
                    title:'北森组织架构岗位:',
                    content:'在该组织下的岗位即为该角色'
                },
            ],
           value:localStorage.getItem('currentIndex')||'0',
           defaultProps: {
                children: 'children',
                label: 'name',
            },
        };
    },
  async  created() {
        this.value=localStorage.getItem('currentIndex')
    },
    watch:{
        filterText(val) {
            this.$refs.selectTree.filter(val);
        },
        dialogVisible(val){
            if(!val){
             this.filterText=''
            }
            if(val&&this.value==='1'){
                this.$nextTick(()=>{
                    let set2=this.$refs.selectTree.setCheckedKeys([]);
                })
            }
        }
    },
    methods: {
        clearForm(){
            this.checkAll=false
            this.checkAll2=false
            this.selectList1=[]
            this.selectList2=[]
           if(this.value==='0'){
            this.$refs.tree.setCheckedKeys([])
           }else if(this.value==='1'){
            this.$refs.selectTree.setCheckedKeys([])
           }
           
        },
      async  getUserTemplateList(){
            try {
             await   downLoadXlsx('sysRole/download/userTemplate',{})
            } catch (error) {
                
            }
        },
          // 上传前检查文件大小的方法
            beforeUpload(file) {
            // 检查文件大小是否小于1MB
            if (file.size > 1024 * 1024) {
                this.$message.error('上传文件大小不能超过1MB');
                this.fileList=[]
                return false; // 阻止上传操作
            }
            // 允许上传
            return true;
            },
            // 文件上传
        uploadFile(param) {
         this.fileList = [param.file]
        },
        // 移除文件
        removeFile() {
         this.fileList = []
        },
        filterNode(value, data) {
            if (!value) return true;
            return data.label.indexOf(value) !== -1;
        },
        handleCheckAll(val){ // 全选按钮
            let res=[]
            if(val){
                this.$refs.tree.setCheckedNodes(this.treeData)
                let arr=this.treeData
                res= flattenTree(arr)
                this.selectList2=res
            } else{
                this.$refs.tree.setCheckedKeys([])
                this.selectList2=[]
            }
        },
        handleCheck2(){
            // 获取选中的节点
            const checkedNodes1 = this.$refs.selectTree.getCheckedNodes();
            const filteredCheckedNodes = checkedNodes1.filter(node => {
                if(!node.children){
                    // 添加额外的逻辑来检查节点是否符合过滤条件
                    return this.filterNode(this.filterText, node);
                }
                
            });
            let res= flattenTree(filteredCheckedNodes)
            this.selectList2=res
        },
        handleCheck(){ // 选中子节点
            let selectAll=this.$refs.tree.getCheckedKeys()
            let arr=this.treeData
            let res= flattenTree(arr)
            this.checkAll = selectAll.length === res.length;
            this.selectList1=selectAll
        },
        inputHnadle(){
            console.log('value', this.value);
        },
        cancelHandle(){
            this.$emit('changeVisible',false)
            this.fileList=[]
        },
        async submitImport() {
            const import_file = this.fileList[0] ? this.fileList[0] : ''
            if (import_file === '') {
                this.$message({
                message: '请选择导入文件！',
                type: 'warning'
                })
                return false
            }
            if (!this.flag) {
                this.flag = true
                let form = new FormData()
                form.append('file', import_file)
               try {
                const ret = await importXlsx('sysRole/readFileData', form)
                if (ret?.code === 0) {
                    if (ret.data?.errorUser?.length>0) {
                        let msg=ret?.data.errorUser.join(',')
                        this.$message.error((msg+'工号无效，请检查后上传') || '导入失败')
                    } else {
                        this.$message.success('导入成功')
                        this.$emit('changeVisible',false,ret.data.user)
                        this.fileList=[]
                    }
                } else {
                    this.$message.error(ret?.message || '导入失败')
                }
               } catch (error) {
                this.$message.error(error || '导入失败')
               } finally{
                this.flag = false
               }
               
            }
            },
        confirmHnadle(){      
            let arr1=null
            let arr2=null
            switch(this.value){
                case '0':
                    arr1=flattenTreeByIds(this.treeData,this.selectList1)
                    this.$emit('changeVisible',false,arr1)
                    this.clearForm()
                    break;
                case '1':
                    arr2=flattenTreeByIds(this.treeData,this.selectList2)
                    this.$emit('changeVisible',false,arr2)
                    this.clearForm()
                    break;
                default:
                    this.submitImport()
                    break;
            }
        },
        handleClose(){
            this.fileList=[]
            this.$emit('changeVisible',false)
        }
    },
};
</script>
<style lang="scss" scoped>
.dialog_main{
.dialog_content{
    padding: 20px;
    .dialog_content_box{
        margin-top: 20px;
        display: flex;
        .dialog_content_box_left{
            width: 20%;
            .dialog_content_box_left_icon{
                color: $color-theme-normal;
            }
        }
        .dialog_content_box_right{
            width: 80%;
            height: 400px;
            overflow-y: scroll;
            border: 1px solid rgb(218, 218, 218);
            .dialog_content_box_right_item2{
                 margin: 10px;
                .dialog_content_box_right_itemPinpput2{
                    width: 60%;
                }
            }
            
        }
    }
    
}
}
.custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
}
.temClick:hover{
    cursor: pointer;
}
.temClick{
    width: 120px;
    height: 40px;
    line-height: 40px;
    color: #409eff;
    // margin-left: -120px;
    // margin-top: 10px;
    // background: red;
    // height: 40px;
}
</style>
